/* White topbar for the login page */
.login-navbar {
    background-color: white;
    display: flex;
    justify-content: space-between; /* Space between logo and links */
    align-items: center;
    padding: 20px;
    width: 100%;
    max-width: 100vw; /* Ensure navbar doesn't exceed viewport */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 10;
    overflow: hidden;
    box-sizing: border-box; /* Prevent content overflow */
}

.login-logo-container {
    display: flex;
    align-items: center;
}

.login-logo-container img {
    width: 50px;
    height: auto;
}

.login-logo-container span {
    color: black;
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 10px;
}

/* Right-side menu links */
.login-nav-links {
    display: flex;
    gap: 20px;
}

.login-nav-links a {
    font-size: 1rem;
    color: #455BF1;
    text-decoration: none;
}

.login-nav-links a:hover {
    text-decoration: underline;
}

/* Main content */
.main-content {
    position: relative;
    height: calc(100vh - 80px);  /* Full height minus the navbar */
    overflow: hidden;
    box-sizing: border-box; /* Ensure no extra width */
}

/* Video Background */
.video-hero {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: 0;
    overflow: hidden;
}

/* Video Overlay */
.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Black overlay at 50% opacity */
    z-index: 1; /* Ensure it sits above the video but below the hero content */
}

/* Hero content */
.hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 1;
}

.hero-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.cta-button {
    padding: 10px 20px;
    background-color: white;
    color: black;
    border: none;
    cursor: pointer;
    font-size: 1rem;
}

/* Global scroll prevention */
html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    overflow-y: hidden; /* Disable vertical scroll */
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}