/* Pricing Section */
.pricing-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.pricing-title {
  text-align: center;
  font-size: 2.5rem;
  color: #040e2e;
  margin-bottom: 40px;
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto 60px;
}

.pricing-card {
  background: white;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pricing-card h3 {
  font-size: 1.5rem;
  color: #040e2e;
  margin-bottom: 8px;
  text-align: left;
}

.price {
  font-size: 2.5rem;
  color: #040e2e;
  margin: 20px 0;
}

.price span {
  font-size: 1rem;
  color: #666;
}

/* Product Display */
.product {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.description {
  margin-left: 15px;
}

.description h3 {
  font-size: 1.5rem;
  color: #040e2e;
  margin-bottom: 8px;
}

.description h5 {
  font-size: 1.8rem;
  color: #455BF1;
  margin-bottom: 8px;
}

.plan-description {
  color: #666;
  font-size: 0.9rem;
}

/* Buttons */
.checkout-button {
  width: 100%;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  background-color: #455BF1;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.checkout-button:hover {
  background-color: #3748c8;
}

.checkout-button.featured {
  background-color: #040e2e;
}

.checkout-button.featured:hover {
  background-color: #1a1f3c;
}

/* Features Section */
.features-section {
  margin-top: 60px;
  padding-top: 60px;
  border-top: 1px solid #eee;
}

.features-title {
  text-align: center;
  font-size: 2.5rem;
  color: #040e2e;
  margin-bottom: 20px;
}

.features-subtitle {
  text-align: center;
  font-size: 1.2rem;
  color: #666;
  max-width: 800px;
  margin: 0 auto 40px;
  line-height: 1.6;
}

.features-table {
  max-width: 800px;
  margin: 0 auto;
  overflow-x: auto;
}

.features-table table {
  width: 100%;
  border-collapse: collapse;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
}

.features-table th,
.features-table td {
  padding: 20px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.features-table th {
  background: #040e2e;
  color: white;
  font-weight: 500;
}

.features-table tr:last-child td {
  border-bottom: none;
}

.features-table tr:hover {
  background-color: #f8f9fa;
}

.features-table td:first-child {
  font-weight: 500;
  color: #040e2e;
}

/* Features Comparison Table */
.features-comparison {
  max-width: 1000px;
  margin: 40px auto;
  padding: 0 20px;
}

.features-comparison table {
  width: 100%;
  border-collapse: collapse;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
}

.features-comparison th,
.features-comparison td {
  padding: 16px 24px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.features-comparison th {
  background: #f8f9fa;
  color: #040e2e;
  font-weight: 500;
  font-size: 1.1rem;
}

.features-comparison th:first-child {
  width: 30%;
}

.features-comparison th:nth-child(2) {
  width: 70%;
}

.features-comparison td:first-child {
  color: #040e2e;
  font-weight: 500;
}

.features-comparison td:nth-child(2) {
  color: #666;
  font-size: 0.95rem;
  line-height: 1.4;
}

.features-comparison tr:last-child td {
  border-bottom: none;
}

.features-comparison tr:hover {
  background-color: #f8f9fa;
}

/* Success Section */
.success-section,
.message-section {
  max-width: 800px;
  margin: 60px auto;
  padding: 40px;
  text-align: center;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.success-section h3 {
  color: #40916c;
  font-size: 2rem;
  margin-bottom: 20px;
}

.success-section p {
  color: #666;
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.portal-button {
  background: #040e2e;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.portal-button:hover {
  background: #1a1a1a;
}

.message-section p {
  color: #666;
  font-size: 1.2rem;
  line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 768px) {
  .pricing-grid {
    grid-template-columns: 1fr;
    max-width: 400px;
  }

  .features-table {
    margin: 0 20px;
  }

  .features-table th,
  .features-table td {
    padding: 15px;
    font-size: 0.9rem;
  }

  .features-title {
    font-size: 2rem;
  }

  .pricing-title {
    font-size: 2rem;
  }

  .pricing-card {
    padding: 20px;
  }

  .features-comparison {
    margin: 20px;
    padding: 0;
    overflow-x: auto;
  }

  .features-comparison table {
    min-width: 500px;
  }

  .features-comparison th,
  .features-comparison td {
    padding: 12px;
    font-size: 0.9rem;
  }
}

.checkout-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
}

.checkout-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 28px;
  color: #11142d;
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.pricing-card {
  background: white;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.pricing-card h3 {
  font-size: 20px;
  color: #11142d;
  margin: 0;
}

.price {
  font-size: 24px;
  color: #455bf1;
  font-weight: 500;
}

.description {
  color: #6f767e;
  font-size: 14px;
}

.subscribe-button {
  margin-top: auto;
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background: #455bf1;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.2s;
}

.subscribe-button:hover {
  background: #3748c8;
}

.subscribe-button.dark {
  background: #11142d;
}

.subscribe-button.dark:hover {
  background: #1a1f3c;
}

.subscribe-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

@media (max-width: 640px) {
  .pricing-grid {
    grid-template-columns: 1fr;
  }
}

.message {
  margin: 20px auto;
  padding: 15px 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 600px;
}

.message-info {
  background-color: #e8f4ff;
  color: #0066cc;
  border: 1px solid #b3d7ff;
}

.message-error {
  background-color: #ffe8e8;
  color: #cc0000;
  border: 1px solid #ffb3b3;
} 