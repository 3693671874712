.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensures it overlays everything */
  font-size: 1.5rem;
  font-weight: bold;
}


.app-container.loading {
  overflow: hidden; /* Prevent scrolling during loading */
}
